<template>
    <main>
        <div class="row  m-auto" style="width: 70%">
            <div class="col-xl-10 mt-4">
                <h1>Liste des Vendeurs </h1>
            </div>
            <div class="col-xl-2 mt-4">
                <div class="form-group mt-3 mb-0 ">
                    <b-button class="w-100" @click="$bvModal.show('add-user')" variant="info" size="sm">
                        Nouveau Vendeur
                    </b-button>
                    <add-user v-on:refresh="refresh_data"/>
                </div>
            </div>
        </div>
        <div class="row mt-5 ">
            <div class="col-xl-8 m-auto">
                <table class="text-center">
                    <thead>
                    <tr>
                        <th scope="col">Username</th>
                        <th scope="col">Nom Complet</th>
                        <th scope="col">Date</th>
                        <th scope="col">Magasin</th>
                        <th scope="col">Admin</th>
                        <th scope="col">Actions</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in users" :key='item.id'>
                        <td data-label="Username"> {{item.username}}</td>
                        <td data-label="Nom Complet" >
                            {{item.name}}
                        </td>
                        <td data-label="Date"> {{new Date(item.created_at).toLocaleDateString()}} {{new Date(item.created_at).toLocaleTimeString()}}</td>
                        <td data-label="Magasin">{{item.magasin}}</td>
                        <td data-label="Admin ou non ?" v-if="item.is_admin">Oui</td>
                        <td data-label="Admin ou non ?" v-else>Non</td>
                        <td data-label="Actions">
                            <ul class="list-inline  mb-0 ml-1">
                                <li class="list-inline-item m-0 " @click="showEdit(item.id)"><i class="fas fa-edit"></i>
                                </li>
                                <li class="list-inline-item m-0 " @click="deleteItem(item.id)"><i
                                        class="fas fa-trash-alt" role="button"></i></li>
                            </ul>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <edit-user :id="selectedId" v-on:refresh="refresh_data"/>
        <delete-confirmation :id="selectedId" type="users/" v-on:refresh="refresh_data"/>
    </main>
</template>

<script>

    import DeleteConfirmation from "@/components/deleteConfirmation";
    import AddUser from "@/components/AddUser";
    import EditUser from "@/components/EditUser";
    export default {
        name: "Vendeurs",
        components: {EditUser, AddUser, DeleteConfirmation},
        data() {
            return {
                users: [],
                selectedId: '',



            }
        },
        created() {
            this.refresh_data();

        },
        methods: {




            showEdit(id) {

                this.selectedId = id;
                this.$root.$emit('bv::show::modal', 'edit-user')

            },

            deleteItem(id) {
                this.selectedId = id
                this.$root.$emit('bv::show::modal', 'delete')

            },
            getUsers() {
                this.$http
                    .get('users' )
                    .then(response => {
                        this.users = response.data

                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            refresh_data() {

                this.getUsers();
            },

        },

    }
</script>

<style scoped src="@/assets/table.css">

</style>