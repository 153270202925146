<template>
    <b-modal id="add-user"  ref="modal" size="lg" class="w-100" title="Ajouter Nouveau">

        <form ref="form">
            <div class="row">
                <div class="col-xl-6">
                    <b-form-group label="Nom  Vendeur" label-for="name-input">
                        <b-form-input id="name-input" v-model="item.name"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-xl-6">
                    <b-form-group label="Magasin" >
                        <b-form-select  v-model="item.magasin" :options="items"></b-form-select>
                    </b-form-group>
                </div>


            </div>
            <div class="row">
                <div class="col-xl-6">
                    <b-form-group label="Username" >
                        <b-form-input v-model="item.username"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-xl-6">
                    <b-form-group label="Password" >
                        <b-form-input type="password" v-model="item.password"></b-form-input>
                    </b-form-group>
                </div>



            </div>


        </form>
        <template #modal-footer>
            <div class="w-100">
                <b-button variant="success" size="lg" class="float-right" @click="addItem">Ajouter</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

    export default {
        name: "AddUser",

        data() {
            return {
                items:['PrettyLinge Boutique Sidi Ghanem','PrettyLinge ShowRoom', 'PrettyLinge M Avenue'],

                item:{},

            }
        },
        methods: {
            addItem() {
                this.$http
                    .post('users', this.item)
                    .then(response => {
                        this.$emit('refresh')
                        this.$refs['modal'].hide()
                        this.item={}
                        this.$alertify.success("L'opération a réussi :)")
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },
        },

    }
</script>
