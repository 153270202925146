<template>
    <b-modal id="edit-user"  ref="modal" size="md" class="w-100" title="Modifier" @shown="bani">

        <form ref="form">
            <div class="row">
                <div class="col-xl-6">
                    <b-form-group label="Nom  Vendeur" label-for="name-input">
                        <b-form-input id="name-input" v-model="item.name"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-xl-6">
                    <b-form-group label="Magasin" >
                        <b-form-select  v-model="item.magasin" :options="items"></b-form-select>
                    </b-form-group>
                </div>


            </div>
            <div class="row">
                <div class="col-xl-6">
                    <b-form-group label="Username" >
                        <b-form-input v-model="item.username"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-xl-6">
                    <b-form-group label="Password" >
                        <b-form-input  type="password" v-model="item.password"></b-form-input>
                    </b-form-group>
                </div>



            </div>


        </form>
        <template #modal-footer>
            <div class="w-100">
                <b-button variant="success" size="lg" class="float-right" @click="editItem">Modifier</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

    export default {
        name: "EditUser",
        props:['id'],

        data() {
            return {
                items:['PrettyLinge Boutique Sidi Ghanem','PrettyLinge ShowRoom', 'PrettyLinge M Avenue'],

                item:{},

            }
        },
        methods: {
            bani(){
                this.$http
                    .get('users/' + this.id)
                    .then(response => {

                        this.item = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })

            },
            editItem() {
                this.$http
                    .post('users/' + this.id, this.item)
                    .then(response => {
                        this.$emit('refresh')
                        this.$refs['modal'].hide()
                        this.item={}
                        this.$alertify.success("L'opération a réussi :)")
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },
        },

    }
</script>
